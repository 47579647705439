import React from "react";
import Bob from "../assets/bob.jpg";
import Pixel from "../assets/pixel game.png";
import Phoenix from "../assets/phoenix.jpg"

const About = () => {
    return  <div className="content2">
            <div className="section p-5 mb-4">
                <div className="container">
                <div className="row align-items-end">
                    <div className="col-12 col-md-6">
                <h1 className="title">Our Story</h1>
                <a>Storm Eye Studios began as a dream between 
                    a group of friends who ended up starting an indie
                    game studio. Although there have been many challenges,
                    the team is dedicated to working with each other and 
                    making games that anyone can enjoy.
                </a>
                </div>
                <div className="col-12 col-md-6">
                <img className="pixel" src={Phoenix} alt="Cit of Phoenix, Arizona"></img>
                </div>
                </div>
                <hr className="line"></hr>
                <div className="row align-items-end">
                    <div className="col-12 col-md-6">
                    <h1 className="title">Game History</h1>
                    <p>SES released a mobile game called Pixel Air Hockey
                        in 2022 for Android. The game was made in Godot
                        and featured gameplay similar to Pong but in an air 
                        hockey setting. The game was rather simplistic but
                        was intended that way so the team can have a released
                        project to build off of.
                    </p>
                    </div>
                    <div className="col-12 col-md-6">
                    <img className="pixel" src={Pixel} alt="Mobile video game"></img>
                    </div>
                    <hr className="line"></hr>
                    </div>
                    <div className="row align-items-end">
                        <div className="col-12 col-md-6">
                    <h1 className="title">Animation</h1>
                    <p>SES had struggles after Pixel Air Hockey
                        due to a variety of reasons, but recently
                        the studio decided to give 2D animation a try.
                        The team is currently working on an animated
                        short that will release summer 2024. Afterwards,
                        SES will pivot back to game development.
                    </p>
                    </div>
                    <div className="col-12 col-md-6">
                    <img className="pixel" src={Bob} alt="2D drawing"></img>
                    </div>
                    </div>
                </div>
            </div>
        </div>
};

export default About;