// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
    margin: 20px;
    text-align: center;
    color: #ffffff;
    background-color: #0d0d0e;
}`, "",{"version":3,"sources":["webpack://./src/componets/Footer/style.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,kBAAkB;IAClB,cAAc;IACd,yBAAyB;AAC7B","sourcesContent":[".footer {\n    margin: 20px;\n    text-align: center;\n    color: #ffffff;\n    background-color: #0d0d0e;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
