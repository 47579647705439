import React, {useState} from "react";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from "./componets/Header";
import Footer from "./componets/Footer";
import Page from "./page";
import Home from './page/Home';
import Contact from "./page/Contact";
import About from "./page/About";

// const pages = ['home', 'about', 'contact']

function App() {
  // const [currentPage, setCurrentPage] = useState('home')
  return (
    <Router>
      <div>
        <Header />
        <Routes>
          <Route
          path = "/"
          element={<Home />}
          />
          <Route
          path="/about"
          element={<About/>}
          />
          <Route 
          path = "/contact"
          element={<Contact />}
          />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
