// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `Header {
    background-color: #0D0D0E;
    color: #ffffff;
}

img {
    height: 5%;
    width: 5%;
}

.button {
    outline: 0 !important;
    border: 0 !important;
    background-color: #0D0D0E;
    color: #ffffff;
    transition: all ease-in-out 0.3s;
}

.button:hover {
    transform: translateY(-3px);
  }`, "",{"version":3,"sources":["webpack://./src/componets/Header/style.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,cAAc;AAClB;;AAEA;IACI,UAAU;IACV,SAAS;AACb;;AAEA;IACI,qBAAqB;IACrB,oBAAoB;IACpB,yBAAyB;IACzB,cAAc;IACd,gCAAgC;AACpC;;AAEA;IACI,2BAA2B;EAC7B","sourcesContent":["Header {\n    background-color: #0D0D0E;\n    color: #ffffff;\n}\n\nimg {\n    height: 5%;\n    width: 5%;\n}\n\n.button {\n    outline: 0 !important;\n    border: 0 !important;\n    background-color: #0D0D0E;\n    color: #ffffff;\n    transition: all ease-in-out 0.3s;\n}\n\n.button:hover {\n    transform: translateY(-3px);\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
