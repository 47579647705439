import React from 'react';
import './style.css'
import Nav from '../Navigation';
import headerImg from '../../assets/sesLogo.png';

const Header = () => {
    return (
        <header>
            <div>
            <img src={headerImg} alt='logo' className='logo-image'>
                </img>
              <Nav
                />
                
            </div>
        </header>
    );
};

export default Header;