// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `nav {
    background-color: #0D0D0E;
    color: #ffffff;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    padding: 50px;
}

.button {
    outline: 0 !important;
    border: 0 !important;
    background-color: #0D0D0E;
    color: #ffffff;
    transition: all ease-in-out 0.3s;

}

.button:hover {
    transform: translateY(-3px);
  }`, "",{"version":3,"sources":["webpack://./src/componets/Navigation/style.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,cAAc;IACd,mBAAmB;IACnB,uBAAuB;IACvB,eAAe;IACf,aAAa;AACjB;;AAEA;IACI,qBAAqB;IACrB,oBAAoB;IACpB,yBAAyB;IACzB,cAAc;IACd,gCAAgC;;AAEpC;;AAEA;IACI,2BAA2B;EAC7B","sourcesContent":["nav {\n    background-color: #0D0D0E;\n    color: #ffffff;\n    align-items: center;\n    justify-content: center;\n    font-size: 20px;\n    padding: 50px;\n}\n\n.button {\n    outline: 0 !important;\n    border: 0 !important;\n    background-color: #0D0D0E;\n    color: #ffffff;\n    transition: all ease-in-out 0.3s;\n\n}\n\n.button:hover {\n    transform: translateY(-3px);\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
