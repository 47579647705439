import React from 'react';
import './style.css';
import { Link } from 'react-router-dom';

const Nav = () => {
    return <nav className='nav text-light mb-4 display-flex align-center'>
      <Link to="/">
        <button className='button'>Home</button>
      </Link>
      <Link to="/about">
        <button className='button'>About</button>
      </Link>
      <Link to="/contact">
        <button className='button'>Contact</button>
      </Link>
    </nav>
};

export default Nav;