// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vl {
    border-left: 2px solid gray;
    height: 500px;
    position: absolute;
    left: 50%;
    margin-left: -3px;
    top: 0;
  }

  body, html {
    background-color:  #0D0D0E;
    color: #ffffff;
  }

  .content {
    height: 800px;
  }

  .content2 {
    height: 1000px;
  }

.image {
  width: 600px;
  height: 300px;
}

.title {
  font-size: 50px;
  margin-bottom: 120px;
}

.pic {
  width: 25%;
  height: 25%;
}

.pixel {
  width: 400px;
  height: 300px;
}

.line {
  margin: 20px;
}

@media screen and (max-width: 768px) {
  .content {
    height: 1200px;
  }
  .content2 {
    height: 1400px;
  }

}`, "",{"version":3,"sources":["webpack://./src/page/style.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;IAC3B,aAAa;IACb,kBAAkB;IAClB,SAAS;IACT,iBAAiB;IACjB,MAAM;EACR;;EAEA;IACE,0BAA0B;IAC1B,cAAc;EAChB;;EAEA;IACE,aAAa;EACf;;EAEA;IACE,cAAc;EAChB;;AAEF;EACE,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,eAAe;EACf,oBAAoB;AACtB;;AAEA;EACE,UAAU;EACV,WAAW;AACb;;AAEA;EACE,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,YAAY;AACd;;AAEA;EACE;IACE,cAAc;EAChB;EACA;IACE,cAAc;EAChB;;AAEF","sourcesContent":[".vl {\n    border-left: 2px solid gray;\n    height: 500px;\n    position: absolute;\n    left: 50%;\n    margin-left: -3px;\n    top: 0;\n  }\n\n  body, html {\n    background-color:  #0D0D0E;\n    color: #ffffff;\n  }\n\n  .content {\n    height: 800px;\n  }\n\n  .content2 {\n    height: 1000px;\n  }\n\n.image {\n  width: 600px;\n  height: 300px;\n}\n\n.title {\n  font-size: 50px;\n  margin-bottom: 120px;\n}\n\n.pic {\n  width: 25%;\n  height: 25%;\n}\n\n.pixel {\n  width: 400px;\n  height: 300px;\n}\n\n.line {\n  margin: 20px;\n}\n\n@media screen and (max-width: 768px) {\n  .content {\n    height: 1200px;\n  }\n  .content2 {\n    height: 1400px;\n  }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
