// import React from "react";
// import Contact from "./Contact";
// import Home from "./Home";
// import About from "./About";
// import './style.css';

// const Page = ({currentPage}) => {

//     const renderPage = (page) => {
//         switch (page) {
//             case 'contact':
//                 return <Contact />;
//             case 'about':
//                 return <About />;
//             default: return <Home />;
//         }
    
//     }
//     return <section className="container">
//         <h2 className="mb-4">{currentPage}</h2>
//         {renderPage(currentPage)}
//         </section>;
// };

// export default Page;