import React from "react";
import './style.css';
import office from "../assets/office.jpg"

const Home = () => {
    return <div className="content">
        <div className="section p-5 mb-4">
            <div className="container">
                <div className="row align-items-end">
                <div className="col-12 col-md-6">
                <h1 className="title">Game Development</h1>
                     <a>Storm Eye Studios is an independent game developer
                     located in the Phoenix metro area in Arizona. This
                     studio works on projects utilizing the Godot engine. We 
                     hope to make games that will be enjoyed by many across the
                     world.
                    </a>
                    </div>
                    <div className="col-12 col-md-6">
                    <img className="image" src={office} alt="office room"></img>
                    </div>
                </div>
            </div>
        </div>
    </div>
};

export default Home;